import { Helmet } from "react-helmet";
import HenkelLogo from "../components/HenkelLogo";
import PaddedContainer from "../components/PaddedContainer";
import '../css/safetyVideo.css';

function WelcomePage() {
    return (
        <div className="safetyVideoContainer">
            <Helmet>
                <title>Welcome</title>
                <meta charset="UTF-8" />
                <meta name="title" content="Henkel Algeria" />
                <meta name="description" content="Henkel Algeria Meeting Form" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <HenkelLogo />
            <div className='leftAlignedContainer'>
                <PaddedContainer >
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom:"8px"}}>
                        <h3>Welcome to Algeria</h3>
                    </div>

                    <h2 style={{ fontSize: "22px", fontWeight: "700", color: "#EC1B23", marginBottom: "24px" }}>Welcome to Henkel Reghaia</h2>
                    <div style={{ display: "flex", flexDirection: "column", height: "100%", flexGrow: "1", justifyContent: "space-between", width: "100%" }}>
                        <video playsInline autoPlay={false} preload='auto' controls style={{ width: "100%", borderRadius: "12px" }}>
                            <source src='https://cactus-dev.nebultech.com/public/Welcome.mp4' type="video/mp4" />
                        </video>

                    </div>

                </PaddedContainer>
            </div>

        </div>
    )
}

export default WelcomePage;