import { Helmet } from "react-helmet";
import HenkelLogo from "../components/HenkelLogo";
import PaddedContainer from "../components/PaddedContainer";
import TitleHeader from "../components/TitleHeader";
import '../css/formQuiz.css'
import { useNavigate, useSearchParams } from "react-router-dom";

function QuizFailure() {

    const navigate = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const nQuestions = searchParams.get("nQuestions")
    const wrongQs = searchParams.get("wrongQs")

    return (
        <div className="formQuizContainer">
            <Helmet>
                <title>Quiz Result</title>
                <meta charset="UTF-8" />
                <meta name="title" content="Henkel Algeria" />
                <meta name="description" content="Henkel Algeria Meeting Form" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <HenkelLogo />
            <div className="centerAlignedContainer">
                <PaddedContainer>
                    <TitleHeader
                        title={"The Quiz Result"}
                        content={"Oops! You didn't get all the answers right. Please review the instructional video and try the quiz again."}
                    />
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "48vw", height: "48vw", border: "2px solid #EC1B23", borderRadius: "16px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h1 style={{ fontSize: "88px", color: "#EC1B23" }}>{wrongQs}/{nQuestions}</h1>
                        </div>
                    </div>
                    <button onClick={() => navigate("/safetyvideo")} style={{ backgroundColor: "#EC1B23" }} className='henkelButton'>Review the video</button>
                </PaddedContainer>
            </div>
        </div>
    )
}

export default QuizFailure;