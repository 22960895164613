import { Helmet } from 'react-helmet';
import HenkelLogo from '../components/HenkelLogo';
import '../css/formQuiz.css';
import PaddedContainer from '../components/PaddedContainer';
import TitleHeader from '../components/TitleHeader';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Checkbox from 'react-custom-checkbox';

const content = [
    {
        question: "Why is it important to stay within designated visitor areas ?",
        answers: [
            "To avoid disturbing employees", 
            "For your own safety and to prevent access to hazardous areas", 
            "To avoid getting lost in the facility",
            "To help the company meet productivity goals"
        ], 
        correctAnswerIndex: [1]
    },
    { 
        question: "As a visitor, are you required to wear Personal Protective Equipment (PPE) ?", 
        answers: [
            "No, only employees need to wear PPE", 
            "Yes, but only in specific areas of the facility", 
            "No, as long as you're careful",
            "Yes, at all times regardless of where you are"
        ], 
        correctAnswerIndex: [1] 
    },
    { 
        question: "What is the first thing you should do upon arriving at the facility as a visitor ?", 
        answers: [
            "Start exploring the work areas", 
            "Wait outside until someone invites you in", 
            "Report to the reception or designated point and sign in",
            "Head straight to the cafeteria"
        ], 
        correctAnswerIndex: [2] 
    },
    { 
        question: "Who is responsible for maintaining safety in the workplace ?", 
        answers: [
            "Only the safety officer", 
            "Only the management team", 
            "Every employee",
            "External auditors"
        ], 
        correctAnswerIndex: [2] 
    },
    { 
        question: "What is the primary goal of a SHE induction ?", 
        answers: [
            "To familiarize employees with the company's financial goals", 
            "To ensure employees understand safety, health, and environmental policies", 
            "To train employees in customer service skills",
            "To provide technical training for job-specific tasks"
        ], 
        correctAnswerIndex: [1] 
    },
    { 
        question: "What should you do in case of a fire alarm during work hours ?", 
        answers: [
            "Continue working until someone tells you to stop", 
            "Gather your belongings and exit the building slowly", 
            "Evacuate immediately following the designated emergency exit route",
            "Wait for confirmation from a co-worker"
        ], 
        correctAnswerIndex: [2] 
    },
]


function FormQuiz() {

    const navigate = useNavigate();
    const [answers, setAnswers] = useState([]);
    const [errorMessagesVisible, setErrorMessagesVisible] = useState([]);

    useEffect(() => {
        // Create an array with no correct answer to init the quiz
        setAnswers(Array(content.length).fill([]));
        setErrorMessagesVisible(Array(content.length).fill(false));
    }, []);

    // Redo this as QCM
    const answerQuestion = (questionIndex, answerIndex, checked) => {
        setErrorMessagesVisible(prevErr =>
            prevErr.map((err, i) => i === questionIndex ? false : err)
        );

        const newResponse = [].concat(answers[questionIndex]);
        const index = newResponse.indexOf(answerIndex);
        if (checked && index === -1) {
            newResponse.push(answerIndex);
        }
        else {
            newResponse.splice(index, 1);
        }
        newResponse.sort();
        setAnswers(previousAnswers =>
            previousAnswers.map((answer, i) => {
                const val = i === questionIndex ? newResponse : answer;
                return val;
            })
        );

    }

    const handleSubmit = () => {
        // navigate("/qrdownload")
        let shouldCancel = false;
        let errorCounter = 0;
        for (let index = 0; index < answers.length; index++) {
            const answer = answers[index];
            
            if (answer.length === 0) {
                shouldCancel = true;
                setErrorMessagesVisible(prevErr =>
                    prevErr.map((err, i) => i === index ? true : err)
                );
            }
            if (answer.length !== content[index].correctAnswerIndex.length) {
                errorCounter++;
                continue;
            }
            if (!answer.every((u, i) => {
                
                return u === content[index].correctAnswerIndex[i];
            })) {
                errorCounter++;
            }
        }


        if (shouldCancel) return;

        if (errorCounter > 0) return navigate(`/quizfailure?nQuestions=${content.length}&wrongQs=${content.length - errorCounter}`);

        return navigate(`/quizsuccess?nQuestions=${content.length}`);

    }

    return (
        <div className="formQuizContainer">
            <Helmet>
                <title>Safety Instruction Quiz</title>
                <meta charset="UTF-8" />
                <meta name="title" content="Henkel Algeria" />
                <meta name="description" content="Henkel Algeria Meeting Form" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <HenkelLogo />
            <div className="centerAlignedContainer">
                <PaddedContainer>
                    <TitleHeader
                        title={"Safety instruction Quiz"}
                        content={"Please answer this quiz correctly to download your access QR code."}
                    />
                    {content.map((element, index) => (
                        <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                            <label>{index + 1}. {element.question}</label>
                            {element.answers.map((answer, aIndex) => (
                                <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row" }}>
                                    <Checkbox
                                        style={{ marginRight: "8px", accentColor: "#EC1B23" }}
                                        borderColor="#EC1B23" type='checkbox'
                                        onChange={(checked) => answerQuestion(index, aIndex, checked)}
                                        name={index}
                                        value={aIndex}
                                        icon={(<div style={{ backgroundColor: "#EC1B23", borderRadius: 2, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} ><img src="./checked.png" style={{ height: "50%" }} alt="" /></div>)}
                                    />
                                    <p className='answers'>{answer}</p>
                                </div>
                            ))}
                            {errorMessagesVisible[index] ? (<p style={{ color: "#EC1B23", fontSize: "12px" }}>*Please complete this question to proceed</p>) : ""}
                        </div>
                    ))}

                    <button onClick={handleSubmit} style={{ backgroundColor: "#EC1B23" }} className='henkelButton'>Submit</button>
                </PaddedContainer>
            </div>
        </div>
    )
}

export default FormQuiz;