import { Helmet } from "react-helmet";
import HenkelLogo from "../components/HenkelLogo";
import PaddedContainer from "../components/PaddedContainer";
import TitleHeader from "../components/TitleHeader";
import '../css/visitorPage.css'
import VisitorInfoRow from "../components/VisitorInfoRow";
import { useSearchParams } from "react-router-dom";

function VisitorPage() {
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div className="visitorPageContainer">
            <Helmet>
                <title>Visitor Informations</title>
                <meta charset="UTF-8" />
                <meta name="title" content="Henkel Algeria" />
                <meta name="description" content="Henkel Algeria Meeting Form" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <HenkelLogo />
            <div className='leftAlignedContainer'>
                <PaddedContainer>
                    <TitleHeader
                        title={"Visitor informations"}
                        content={"Please ensure that all the information is correct and corresponds to the visitor so that the visit can proceed smoothly."}
                    />
                    <br />
                    <VisitorInfoRow label="Visitor full name" value={searchParams.get("name")} />
                    <VisitorInfoRow label="Visitor email" value={searchParams.get("email")} />
                    <VisitorInfoRow label="Visitor phone" value={searchParams.get("phoneNumber")} />
                    <VisitorInfoRow label="Host name" value={searchParams.get("hostName")} />
                    <VisitorInfoRow label="Purpose of visit" value={searchParams.get("purposeOfVisit")} />
                    <VisitorInfoRow label="Vehicle information" value={searchParams.get("vehicleInfo") ? searchParams.get("vehicleInfo") : "None"} />
                    <VisitorInfoRow label="Emergency contact" value={searchParams.get("emergencyContact") ? searchParams.get("emergencyContact") : "None"} />
                    <VisitorInfoRow label="SHE induction" value={(<span style={{color: "#0ACD00"}}>Done</span>)} />

                </PaddedContainer>
            </div>
        </div>
    )
}

export default VisitorPage;